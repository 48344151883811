import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

import SalesBoost from '../../components/contact-boost'
import Layout from '../../layout/layout'
import Simple from '../../svg/simple.svg'

const Page = () => (

    <Layout>

        <Helmet>
          <title>Sitemap - Maakt producten lokaal vindbaar</title>
          <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
        </Helmet>

        <section className="text-left">
            <div className="inner">
                <div className="text">
                    <h2>Sitemap</h2>
                    <p>
                        <ul>
                            <li><Link to="/nl/">Home</Link></li>
                            <li><Link to="/nl/retailers/">Retailers</Link></li>
                            <ul>
                                <li><Link to="/nl/aanmelden/">Aanmelden bij Zupr</Link></li>
                                <li><Link to="/nl/quickstart/">Quickstart producten toevoegen</Link></li>
                                <li><Link to="/nl/data-en-beveiliging/">Data en beveiliging</Link></li>
                                <li><Link to="/nl/stripe/">Stripe account aanmaken</Link></li>
                                <li><Link to="/nl/app/">App downloaden voor iOS en Android</Link></li>
                                <li><Link to="https://www.zupr.nl/admin/">Inloggen op de Backoffice</Link></li>
                            </ul>
                            <li><Link to="/nl/merken/">Merken</Link></li>
                            <ul>
                                <li><Link to="/nl/datakoppeling/">Datakoppeling en feeds</Link></li>
                                <li><Link to="/nl/storelocator/">Storelocator technologie</Link></li>
                            </ul>
                            <li><Link to="/nl/winkelgebieden/">Winkelgebieden</Link></li>
                            <ul>
                                <li><Link to="/nl/retailers/">Retailers</Link></li>
                                <li><Link to="/nl/retailplatform/">Retailplatform</Link></li>
                            </ul>
                            <li><Link to="/nl/tarieven/">Tarieven</Link></li>
                            <li><Link to="/nl/over-ons/">Over ons</Link></li>
                            <ul>
                                <li><Link to="/nl/via-2020-softwareontwikkeling/">Via 2020 softwareontwikkeling</Link></li>
                                <li><Link to="/nl/cookiebeleid/">Cookiebeleid</Link></li>
                                <li><Link to="/nl/algemene-voorwaarden/">Algemene voorwaarden</Link></li>
                                <li><Link to="/nl/gebruiksvoorwaarden/">Gebruiksvoorwaarden</Link></li>
                            </ul>
                        </ul>
                    </p>
                </div>
                <div className="image">
                    <Simple />
                </div>
            </div>
        </section>

        <SalesBoost />

    </Layout>
)

export default Page
